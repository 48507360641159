import { gql } from '@apollo/client';

export default gql`
  query CustomerTeamSearch($customerTeamId: ID){
    customerTeamSearch(customerTeamId: $customerTeamId) {
      id
      company {
        id
        name
      }
      customerUsers {
        id
        firstName
        lastName
        userGroups {
          id
          name
        }
        status
      }
      name
      userGroups {
        id
        name
      }
    }
  }
`;
