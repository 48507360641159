import { gql } from '@apollo/client';

export default gql`
  query VesselManagementSearch($searchString: String, $pagination: PaginationInput) {
    vesselSearch(searchString: $searchString, pagination: $pagination) {
      id
      name
      naccsCode
      imo
      mmsi
    }
  }
`;
