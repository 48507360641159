const AUTH_TOKEN = `X-Auth-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`;
const AUTH_ROLE = `X-Role-${process.env.REACT_APP_COOKIE_NAME_SUFFIX}`;
const EXPIRATION_DAYS = 30;
const COOKIE_PATH = '/';

/**
 * To return corresponding value of the given key stored in cookie
 * @param {string} parseKey
 * @returns {string | null}
 */
const parseCookie = (parseKey) => {
  let matchedValue = null;

  document.cookie.split(';').some((cookieItem) => {
    const [key, value] = cookieItem.split('=');

    if (key.trim() === parseKey) {
      matchedValue = value;
      return true; // returning true will break the loop
    }

    return false;
  });

  return matchedValue;
};

const getUserTokenFromCookie = () => parseCookie(AUTH_TOKEN);
const getUserTypeFromCookie = () => parseCookie(AUTH_ROLE);

/**
 * To reset auth information in cookie
 */
const removeCookieToken = () => {
  const domainCookie = `Domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
  const expirationCookie = 'Expires=Thu, 01 Jan 1970 00:00:01 GMT';

  const authTokenCookie = `${AUTH_TOKEN}=`;
  const userTypeCookie = `${AUTH_ROLE}=`;

  /**
   * Clearing auth cookies for all the possible routes is a temporary solution.
   * TODO: Remove this paths and forEach loop after 2022-03-15.
   * After the specified date, we should not have the concerned situation,
   * where multiple auth cookies are created with other route than Route=/
   */
  const paths = [
    '/',
    'shipments',
    'shipment',
    'quote',
    'vessel-schedule',
    'accounts',
    'companies',
    'products',
    'customer-partners',
    'places',
    'settings',
    'chat',
    'quote-requests',
    'quote-request',
    'vessels',
    'milestone-log',
  ];
  paths.forEach((path) => {
    const pathCookie = `Path=${path}`;

    document.cookie = `${authTokenCookie};${domainCookie};${expirationCookie};${pathCookie}`;
    document.cookie = `${userTypeCookie};${domainCookie};${expirationCookie};${pathCookie}`;
  });

  // TODO: Replace below commented code with the above forEach block
  // document.cookie = `${authTokenCookie};${domainCookie};${expirationCookie};Path=${COOKIE_PATH}`;
  // document.cookie = `${userTypeCookie};${domainCookie};${expirationCookie};Path=${COOKIE_PATH}`;
};

/**
 * To set auth information in cookie (Token, Role, Domain, and Expires)
 * @param {string} authToken
 * @param {string} userType
 */
const storeCookieToken = (authToken, userType) => {
  removeCookieToken();

  const domainCookie = `Domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;

  const expiration = new Date();
  expiration.setDate(new Date().getDate() + EXPIRATION_DAYS);
  const expirationCookie = `Expires=${expiration.toUTCString()}`;

  const authTokenCookie = `${AUTH_TOKEN}=${authToken}`;
  const userTypeCookie = `${AUTH_ROLE}=${userType}`;

  document.cookie = `${authTokenCookie};${domainCookie};${expirationCookie};Path=${COOKIE_PATH}`;
  document.cookie = `${userTypeCookie};${domainCookie};${expirationCookie};Path=${COOKIE_PATH}`;
};

const extendCookieTokenExpiration = () => {
  const authToken = getUserTokenFromCookie();
  const userType = getUserTypeFromCookie();
  storeCookieToken(authToken, userType);
};

export {
  getUserTokenFromCookie,
  getUserTypeFromCookie,
  removeCookieToken,
  extendCookieTokenExpiration,
  storeCookieToken,
};
