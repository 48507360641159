import { gql } from '@apollo/client';

export default gql`
  mutation createCompany($args: CompanyInput!) {
    createCompany(companyArgs: $args) {
      id
      name
      nameEn
    }
  }
`;
