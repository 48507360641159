import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import './assets/base.scss';
import App from './App';
import configureStore from './config/configureStore';
import { AuthTokenProvider } from './context/AuthTokenContext';

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = () => {
  ReactDOM.render(
    <AuthTokenProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </AuthTokenProvider>,
    rootElement,
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
