import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchMilestones(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      id
      cargo {
        id
        cargoType
        containers {
          containerNumber
          id
        }
      }
      customerTeam {
        id
        name
        company {
          name
        }
        customerUsers {
          id
          name
        }
      }
      milestones {
        id
        milestoneEvents {
          id
          accuracy
          addressId
          address {
            name
          }
          carContactInfo {
            carNumber
            companyName
            driverName
            phoneNumber
          }
          container {
            id
            containerNumber
            containerSealNumber
          }
          completionDoneAt
          completionDoneAtHasTime
          completionEstimatedAt
          completionEstimatedAtHasTime
          detailedReason
          status
          eventName
          drayageReservationStatus
          milestoneId
        }
        completionDoneAt
        completionDoneAtHasTime
        completionEstimatedAt
        completionEstimatedAtHasTime
        etaMismatch
        isDeletable
        milestoneType
        mtTrackingData {
          eta
          marineTrafficUrl
        }
        port {
          id
          name
          unlocode
        }
        vesselName
        vesselId
        voyageNumber
        status
        vessel {
          id
          imo
        }
      }
      status
      tradeDirection
      urgencyLevel
    }
  }
`;
