import { gql } from '@apollo/client';

export default gql`
  query productSearch(
    $customerTeamId: ID!,
    $includeDeleted: Boolean,
    $pagination: PaginationInput,
    $productId: ID,
    $searchString: String,
  ) {
    productSearch(
      customerTeamId: $customerTeamId
      includeDeleted: $includeDeleted
      pagination: $pagination
      productId: $productId
      searchString: $searchString
    ) {
      category
      country {
        nameTranslations {
          en
          ja
        }
      }
      countryOfOrigin
      height
      hsCode
      id
      length
      name
      normalizedName
      numberOfInnerPackages
      packagingType
      productCode
      productUrl
      unitPrice
      unitPriceCurrency
      weight
      width
    }
  }
`;
