import * as types from './types';
import {
  COMPANY_ID,
  POL_UNLOCODE,
  POD_UNLOCODE,
  ASSIGNED_INTERNAL_USER_ID,
  ACTIVITY_STATUS,
  RESPONSIBILITY_TYPES,
  ORDER_OPTION,
  SEARCH_STRING,
} from '../../utils/constants/auth';
import { RESPONSIBILITY_OPTIONS } from '../../utils/constants/shipments';

// Load the values from the local storage
const savedCompanyId = localStorage.getItem(COMPANY_ID);
const savedPolId = localStorage.getItem(POL_UNLOCODE) || null;
const savedPodId = localStorage.getItem(POD_UNLOCODE) || null;
const savedActivityStatus = localStorage.getItem(ACTIVITY_STATUS);
const savedResponsibilityTypes = localStorage.getItem(RESPONSIBILITY_TYPES);
const savedAssignedInternalUserId = localStorage.getItem(ASSIGNED_INTERNAL_USER_ID);
const savedOrderOption = localStorage.getItem(ORDER_OPTION);
const savedSearchString = localStorage.getItem(SEARCH_STRING);

const initialOptions = {
  companyId: savedCompanyId === 'null' ? null : savedCompanyId,
  polId: savedPolId === 'null' ? null : savedPolId,
  podId: savedPodId === 'null' ? null : savedPodId,
  activityStatus: savedActivityStatus === 'null' ? 'active_only' : savedActivityStatus,
  responsibilityTypes: savedResponsibilityTypes === 'null' ? RESPONSIBILITY_OPTIONS.shippio : savedResponsibilityTypes,
  orderOption: savedOrderOption === 'null' ? 'created_at' : savedOrderOption,
  assignedInternalUserId: savedAssignedInternalUserId === 'null' ? null : savedAssignedInternalUserId,
  searchString: savedSearchString,
};

const shipmentListFilters = (state = initialOptions, action) => {
  switch (action.type) {
  case types.SEARCH_FILTER_EDIT: {
    const {
      companyId,
      polId,
      podId,
      activityStatus,
      responsibilityTypes,
      assignedInternalUserId,
      orderOption,
      searchString,
    } = action.payload;

    // Save to the local storage
    localStorage.setItem(COMPANY_ID, companyId);
    localStorage.setItem(POL_UNLOCODE, polId);
    localStorage.setItem(POD_UNLOCODE, podId);
    localStorage.setItem(ASSIGNED_INTERNAL_USER_ID, assignedInternalUserId);
    localStorage.setItem(ACTIVITY_STATUS, activityStatus);
    localStorage.setItem(RESPONSIBILITY_TYPES, responsibilityTypes);
    localStorage.setItem(ORDER_OPTION, orderOption);
    localStorage.setItem(SEARCH_STRING, searchString);

    return {
      companyId,
      polId,
      podId,
      assignedInternalUserId,
      activityStatus,
      responsibilityTypes,
      orderOption,
      searchString,
    };
  }

  case types.ASSIGNED_INTERNAL_USER_ID_DELETE: {
    localStorage.removeItem(ASSIGNED_INTERNAL_USER_ID);

    return null;
  }

  default:
    return state;
  }
};

export default shipmentListFilters;
