import { gql } from '@apollo/client';

export default gql`
  query QuoteAddressSearch(
    $addressId: ID
    $customerTeamId: ID!
    $includeCustomerTeamRelated: Boolean
    $searchString: String
    $tradePartnerTypes: [TradePartnerTypeEnum!]
    $pagination: PaginationInput
  ) {
    quoteFilteredAddressSearch(
      addressId: $addressId
      customerTeamId: $customerTeamId
      includeCustomerTeamRelated: $includeCustomerTeamRelated
      searchString: $searchString
      tradePartnerTypes: $tradePartnerTypes
      pagination: $pagination
    ) {
      id
      name
      countryCode
    }
  }
`;
