import { gql } from '@apollo/client';

export default gql`
  query CustomerTeamSearch($companyId: ID, $pagination: PaginationInput){
    customerTeamSearch(companyId: $companyId, pagination: $pagination) {
      id
      name
    }
  }
`;
