const STATUS_COLOR = (urgencyLevel) => {
  if (urgencyLevel === 'warning') {
    return { color: '#E8A701' };
  }

  if (urgencyLevel === 'on_track') {
    return { color: '#5A83EC' };
  }

  // Critical
  return { color: '#C73446' };
};

const SHIPMENT_STATUS = {
  carry_in_delay: 'carry_in_delay',
  collection_delay: 'collection_delay',
  completed: 'completed',
  customs_clearance_delay: 'customs_clearance_delay',
  cy_carry_in_delay: 'cy_carry_in_delay',
  delivery_delay: 'delivery_delay',
  departure_delay: 'departure_delay',
  devanning_delay: 'devanning_delay',
  import_request: 'import_request',
  in_transit_delay: 'in_transit_delay',
  in_transit: 'in_transit',
  packing_delay: 'packing_delay',
  pre_shipment: 'pre_shipment',
  preparing: 'preparing',
  unpacking_delay: 'unpacking_delay',
  vanning_delay: 'vanning_delay',
  waiting_for_carry_in: 'waiting_for_carry_in',
  waiting_for_collection: 'waiting_for_collection',
  waiting_for_customs_clearance: 'waiting_for_customs_clearance',
  waiting_for_cy_carry_in: 'waiting_for_cy_carry_in',
  waiting_for_delivery: 'waiting_for_delivery',
  waiting_for_departure: 'waiting_for_departure',
  waiting_for_devanning: 'waiting_for_devanning',
  waiting_for_packing: 'waiting_for_packing',
  waiting_for_unpacking: 'waiting_for_unpacking',
  waiting_for_vanning: 'waiting_for_vanning',
};

const TRANSLATE_SHIPMENT_STATUS = {
  carry_in_delay: '搬入(遅延)',
  collection_delay: '集荷(遅延)',
  completed: '完了',
  customs_clearance_delay: '通関(遅延)',
  cy_carry_in_delay: '船積み(遅延)',
  delivery_delay: '納品(遅延)',
  departure_delay: '出港(遅延)',
  devanning_delay: 'デバンニング(遅延)',
  import_request: '発注待ち',
  in_transit_delay: '輸送中(遅延)',
  in_transit: '輸送中',
  packing_delay: '梱包(遅延)',
  pre_shipment: '発注待ち',
  preparing: '準備中',
  unpacking_delay: '開梱(遅延)',
  vanning_delay: 'バンニング(遅延)',
  waiting_for_carry_in: '搬入待ち',
  waiting_for_collection: '集荷待ち',
  waiting_for_customs_clearance: '通関待ち',
  waiting_for_cy_carry_in: '船積み待ち',
  waiting_for_delivery: '納品待ち',
  waiting_for_departure: '出港待ち',
  waiting_for_devanning: 'デバンニング待ち',
  waiting_for_packing: '梱包待ち',
  waiting_for_unpacking: '開梱待ち',
  waiting_for_vanning: 'バンニング待ち',
};

const SHIPMENTS_PER_PAGE = 100;

const CARGO_TYPE = {
  FCL: 'FCL',
  LCL: 'LCL',
  AIR: 'AIR',
};

const RESPONSIBILITY_OPTIONS = {
  shippio: 'shippio',
  non_shippio: 'non_shippio',
};

export {
  STATUS_COLOR,
  SHIPMENT_STATUS,
  SHIPMENTS_PER_PAGE,
  TRANSLATE_SHIPMENT_STATUS,
  CARGO_TYPE,
  RESPONSIBILITY_OPTIONS,
};
