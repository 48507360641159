import { gql } from '@apollo/client';

export default gql`
  query CompanySearchById($companyId: ID){
    companySearch(companyId: $companyId) {
      id
      name
      nameEn
      customerTeams {
        id
        name
      }
    }
  }
`;
