import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthRoute from './AuthRoute';

import * as ROUTES from '../../config/routes';
import { getUserTokenFromCookie, extendCookieTokenExpiration } from '../../config/cookies';
import { useAuthTokenContext } from '../../context/AuthTokenContext';

/**
 * The AppMain is just a collection of routes based on the routes config file.
 * Whenever you want to add a route, go to config > routes.js and lazy load
 * your page component through there.
 *
 * <Route> component should only be used for public routes.
 * <AuthRoute> component should only be used for private routes (that need either
 * auth or permissions)
 *
 * However this check only happens when the app starts, if at the start the user
 * is Authenticated, no further checks are done.
 *
 * In order to check every route change, we added a check on the Menu component (CustomLink).
 */
function AppMain() {
  const { createAuthorizedClient } = useAuthTokenContext();
  // Check the route list type i.e. if it's a public or protected route based on the
  // presence of the auth token
  const routeType = !getUserTokenFromCookie() ? 'PUBLIC' : 'PROTECTED';

  // Render the list of <Route> or <AuthRoute> routes depending on the route type
  const routeOptions = ROUTES[`${routeType}_ROUTES`].map((route) => {
    if (routeType === 'PROTECTED') {
      return <AuthRoute key={route.path} {...route} />;
    }
    return <Route key={route.path} {...route} />;
  });

  // The default <Redirect> that will be rendered to take any non-existing
  // routes and take care of '/
  const redirectOption = <Redirect {...ROUTES[`${routeType}_REDIRECT`]} />;

  // Extend the cookie duration because the user is authenticated
  // and add its token to the managing context
  useEffect(() => {
    const token = getUserTokenFromCookie();
    if (token) {
      extendCookieTokenExpiration();
      createAuthorizedClient(token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Bringing in a Switch statement so that it's easier to leave any non-matching routes
      to the end with the redirect feature */}
      <Switch>
        {routeOptions}
        <Route render={() => redirectOption} />
        {/* In the templates example this is left at the very bottom, so will leave this here */}
        <ToastContainer />
      </Switch>
    </>
  );
}

export default AppMain;
