const ACTIVITY_STATUS = 'activity-status';
const RESPONSIBILITY_TYPES = 'responsibilityTypes';
const ASSIGNED_INTERNAL_USER_ID = 'assigned-internal-user-id';
const COMPANY_ID = 'company-id';
const LOGOUT_ERROR_CODE = 'sign_in_required';
const ORDER_OPTION = 'order-option';
const POD_UNLOCODE = 'pod-unlocode';
const POL_UNLOCODE = 'pol-unlocode';
const QR_FILTER_COMPANY_ID = 'qr-filter-company-id';
const QR_FILTER_CUSTOMER_TEAM_ID = 'qr-filter-customer-team-id';
const QR_FILTER_STATUS = 'qr-filter-status';
const SEARCH_STRING = 'search-string';
const USER_TYPE = 'Staff';
const CHAT_COMPANY_FILTER = 'chat-filter-company';
const CHAT_CUSTOMER_TEAM_FILTER = 'chat-filter-customer-team';
const CHAT_CHANNEL_TYPE_FILTER = 'chat-filter-channel-type';
// TODO: remove following deprecated constants after some time
const DEPRECATED_CHAT_COMPANY_FILTER = 'chat-filter-company-id';
const DEPRECATED_CHAT_CUSTOMER_TEAM_FILTER = 'chat-filter-customer-team-id';
const DEPRECATED_CHAT_CHANNEL_TYPE_FILTER = 'chat-filter-channel-type-filter';

const LOCAL_STORAGE_REMOVABLE_KEYS = [
  ACTIVITY_STATUS,
  RESPONSIBILITY_TYPES,
  ASSIGNED_INTERNAL_USER_ID,
  COMPANY_ID,
  ORDER_OPTION,
  POD_UNLOCODE,
  POL_UNLOCODE,
  QR_FILTER_COMPANY_ID,
  QR_FILTER_CUSTOMER_TEAM_ID,
  QR_FILTER_STATUS,
  SEARCH_STRING,
  CHAT_COMPANY_FILTER,
  CHAT_CUSTOMER_TEAM_FILTER,
  CHAT_CHANNEL_TYPE_FILTER,
  // TODO: remove following deprecated constants after some time
  DEPRECATED_CHAT_COMPANY_FILTER,
  DEPRECATED_CHAT_CUSTOMER_TEAM_FILTER,
  DEPRECATED_CHAT_CHANNEL_TYPE_FILTER,
];

export {
  ACTIVITY_STATUS,
  RESPONSIBILITY_TYPES,
  ASSIGNED_INTERNAL_USER_ID,
  COMPANY_ID,
  LOCAL_STORAGE_REMOVABLE_KEYS,
  LOGOUT_ERROR_CODE,
  ORDER_OPTION,
  POD_UNLOCODE,
  POL_UNLOCODE,
  QR_FILTER_COMPANY_ID,
  QR_FILTER_CUSTOMER_TEAM_ID,
  QR_FILTER_STATUS,
  SEARCH_STRING,
  USER_TYPE,
  CHAT_COMPANY_FILTER,
  CHAT_CUSTOMER_TEAM_FILTER,
  CHAT_CHANNEL_TYPE_FILTER,
};
